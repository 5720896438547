html,
body {
  height: 100%;
  width: 100%;
  font-size: 14px;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px;
}

body.fontLoaded {
  font-family: "Open Sans", sans-serif !important;
}

#root {
  background-color: #f5f6f7;
  height: 100%;
  width: 100%;
}

/*p,*/
/*label {*/
/*  font-family: "Open Sans", sans-serif !important;*/
/*  line-height: 1.5em;*/
/*}*/

.errorMessages {
  margin: 10px;
}